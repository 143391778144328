import React from 'react'
import bg from '../Images/Page-bg.jpg'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse ,faAnglesRight} from '@fortawesome/free-solid-svg-icons'
import './PageHeadSec.css'
export default function PageHeadSec({PageName}) {
  return (
    <div className='PageHeadSec_Conatiner'>
     <div><img className="PageBg_pic" src={bg} alt="" />
     <div className='PageBg'></div>
     <h1 className='PageBg_Heading'>{PageName}</h1></div> 
     <div className='PageBg_Navigator'>
     <FontAwesomeIcon icon={faHouse}/><Link to="/" className='PageBg_Navigator_Home' state={{TextDecoder:"none"}}>Home</Link><FontAwesomeIcon icon={faAnglesRight}/><div className='PageBg_Navigator_main' >{PageName}</div></div>
    </div>  
  )
}
