import React ,{useEffect}from "react";
import Header from "../Components/Header";
import CarouselSec from "../Components/CarouselSec";
import HomeAboutusSec from "../Components/HomeAboutusSec";
import WhatWeOffer from "../Components/WhatWeOffer";
import WhyChooseUs from "../Components/WhyChooseUs";
import Testmonials from "../Components/Testmonials";
import Footer from "../Components/Footer";
import {useLocation } from 'react-router-dom';
import Instagram from "../Images/instagram.png";

import YouTube from "../Images/youtube.png";
import AlertDialogSlide from "../Components/Dialog";

export default function DashBoard({ openForm, setOpenForm }) {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
  }, [pathname]);
 
  return (
    <div>
      <div className="SocialMedia_logo_container">
        <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
          <img src={Instagram} className="SocialMedia_logo" alt="Instagram" />{" "}
        </a>

        <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
          <img src={YouTube} className="SocialMedia_logo" alt="YouTube" />{" "}
        </a>
      </div>
      <Header setOpenForm={setOpenForm} />
      <CarouselSec setOpenForm={setOpenForm} />
      <HomeAboutusSec />
      <WhatWeOffer setOpenForm={setOpenForm} />
      <WhyChooseUs setOpenForm={setOpenForm} />
      <Testmonials />
      <Footer />
      {<AlertDialogSlide openForm={openForm} setOpenForm={setOpenForm} />}
    </div>
  );
}
