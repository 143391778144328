import React from 'react';
import './App.css';
import DashBoard from './Pages/DashBoard'; 
import AboutUsPage from './Pages/AboutUsPage.js';
import ContactUsPage from './Pages/ContactUsPage';
import FAQpage from './Pages/FAQpage';
import { Route, Routes } from "react-router-dom";
import { useState } from 'react';
function App() {
  const [openForm,setOpenForm]=useState(false);
  return (
    <div className="App">
      <Routes>
        <Route  path="/" element={<DashBoard openForm={openForm} setOpenForm={setOpenForm}/>} />
        <Route path="/aboutus" element={<AboutUsPage openForm={openForm} setOpenForm={setOpenForm}/>} />
        <Route path="/contactus" element={<ContactUsPage openForm={openForm} setOpenForm={setOpenForm}/>} />
        <Route path="/FAQ" element={<FAQpage openForm={openForm} setOpenForm={setOpenForm}/>} />
    </Routes>
      
    </div>
  );
}

export default App;
