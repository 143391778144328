import React, { useRef,useEffect  } from "react";
import "./Style.css";
import Header from "../Components/Header";
import PageHeadSec from "../Components/PageHeadSec";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faClock,
 
} from "@fortawesome/free-solid-svg-icons";
import AlertDialogSlide from "../Components/Dialog";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'
import { useNavigate,useLocation } from 'react-router-dom';

export default function ContactUsPage({ openForm, setOpenForm }) {
  const navigate = useNavigate(); 
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
  }, [pathname]);
 

const form = useRef();
const sendEmail = (e) => {
    e.preventDefault();
    const name = form.current.user_name.value;
    const email = form.current.user_email.value;
    const message = form.current.message.value;

    // Perform validation
    if (!name || !email || !message) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill all the details to submit",
         
      });
      return; // Do not submit the form if any field is empty
    }else{
    emailjs.sendForm('service_sphmd6f', 'template_kt6s5x3', form.current, 'MA1cYJBMAJxjoLTFt')
      .then((result) => {
          console.log(result.text);
          Swal.fire({
            title: "Details shared sucessfully!",
            text: "you will recieve a response from us soon!!!",
            icon: "success"
          });
          navigate('/')
          
      }, (error) => {
          console.log(error.text);
      });
    form.current.reset();
    }
  };
  return (
    <div>
      <Header setOpenForm={setOpenForm} />
      <PageHeadSec PageName={"Contact Us"} />
      <div className="ContactUsSec_Container">
        <div className="ContactUsSec_inner">
          <div className="SideSec_contactUsPage">
            <div className="Address_ContactUs">
              <div className="ContactUsIcons_outer">
                <FontAwesomeIcon
                  className="ContactUsIcons"
                  icon={faLocationDot}
                />
              </div>
              <div className="contactUs_Content">
                <h3 className="Address_ContactUs_heading">Office Address</h3>
                <h5 className="Address_ContactUs_para">
                  D-410,2nd Floor Near Burger Club, Ramphal Chowk, Sector 7 Dwarka, New
                  Delhi-110075
                </h5>
              </div>
            </div>
            <div className="Address_ContactUs">
              <div className="ContactUsIcons_outer">
                <FontAwesomeIcon className="ContactUsIcons" icon={faPhone} />
              </div>
              <div className="contactUs_Content">
                <h3 className="Address_ContactUs_heading">Call Us</h3>
                <h5 className="Address_ContactUs_para">
                  +91 - 9711108932, +91 - 8800857231
                </h5>
              </div>
            </div>
            <div className="Address_ContactUs">
              <div className="ContactUsIcons_outer">
                <FontAwesomeIcon className="ContactUsIcons" icon={faEnvelope} />
              </div>
              <div className="contactUs_Content">
                <h3 className="Address_ContactUs_heading">Email Us</h3>
                <h5 className="Address_ContactUs_para">Anuragaviatorcareer @gmail.com</h5>
              </div>
            </div>
            <div className="Address_ContactUs">
              <div className="ContactUsIcons_outer">
                <FontAwesomeIcon className="ContactUsIcons" icon={faClock} />
              </div>
              <div className="contactUs_Content">
                <h3 className="Address_ContactUs_heading">Office Open</h3>
                <h5 className="Address_ContactUs_para">
                  Mon - Sat (9AM - 6PM)
                </h5>
              </div>
            </div>
          </div>
          <div className="contactUsForm">
            <h2
              style={{ color: "black", fontSize: "2.5em", fontWeight: "600" }}
            >
              Get In Touch
            </h2>
            <h5
              style={{
                color: "#182333",
                fontWeight: "600",
                marginTop: "2vh",
                marginBottom: "2vh",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </h5>
             
            <form ref={form} onSubmit={sendEmail}>
              <label> </label>
              <input className='ContactUs_input' placeholder='Your Name' type="text" name="user_name" />
              <label> </label>
              <input className='ContactUs_input' placeholder='Your Email' type="email" name="user_email" />
              <label> </label>
              <textarea className='ContactUs_input' style={{height:"20vh"}} placeholder='Write Your Message...' name="message" />
              <input className='contactUs_Btn' type="submit" value="Send" />
            </form>
          </div>
        </div>
      </div>
      <Footer />
      {<AlertDialogSlide openForm={openForm} setOpenForm={setOpenForm} />}
    </div>
  );
}
