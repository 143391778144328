import React from 'react'
import './WhatWeOffer.css'
 
import EastIcon from '@mui/icons-material/East';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
export default function WhatWeOffer({ setOpenForm }) {
  const handleForm = () => {
    setOpenForm(true);
  }
  return (
    <div className='WhatWeOfferSec'>
      <h1 className='WhatWeOfferSec_heading'>What We <span style={{ color: "#ffb703" }}>Offer</span></h1>
      <h3 className='WhatWeOfferSec_heading2'>Ground Classes for CPL/ATPL</h3>
      <div className='card_Container'>
        <div className="row   row-cols-md-4 row-cols-sm-2 row-cols-xs-1 g-4">


          <div className="col">
            <div className="card">

              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>40 sessions</p> */}
                <h5 className="card-title">Air Meteorology</h5>
                <p className="card-text card-text-deco">Aviation Meteorology is the study of weather from the unique perspective of the Aviation Industry.</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">

              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>40 sessions</p> */}
                <h5 className="card-title">Air Regulation</h5>
                <p className="card-text card-text-deco">Air Regulation is the study of regulations concerning flight, air safety, airport facilities and other topics associated with rules of air.</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>60 sessions</p> */}
                <h5 className="card-title">Technical General</h5>
                <p className="card-text card-text-deco">In Technical General, we study about the technical aspects of aviation including aerodynamics, aircraft systems and aircraft engines.</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>60 sessions</p> */}
                <h5 className="card-title">Technical Specification</h5>
                <p className="card-text card-text-deco">Technical Specification is all about the specification of an air craft which we are going to endorse in our licence</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>60 sessions</p> */}
                <h5 className="card-title">Air Navigation</h5>
                <p className="card-text card-text-deco">Air navigation involves piloting an aircraft from place to place without breaking the laws applying to aircraft, or endangering the safety of flight.</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>          
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>40 sessions</p> */}
                <h5 className="card-title">RTR(A)</h5>
                <p className="card-text card-text-deco">The Radio Telephony Restricted (Aeronautical) is a professional license of an international standard and is issued by WPC in India.</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>40-80 sessions</p> */}
                <h5 className="card-title">Airline Preparation</h5>
                <p className="card-text card-text-deco">Airline Preparation Classes are professionally managed by Senior Airline Pilots. Cadets are trained for Written Exams, G.D, COMPASS, Psychometric Exam, Interview Preparation.</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>60 sessions</p> */}
                <h5 className="card-title">ATPL Written & Oral</h5>
                <p className="card-text card-text-deco">ATPL Exams are a mandatory requirement for flight crew to qualify for the post of Captain. Additionally, Airlines also prefer fresh license holders with ATPL Exams cleared.</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className='WhatWeOfferSec_Icon'><div className='WhatWeOfferSec_Icon_container'><DataSaverOnIcon className='WhatWeOfferSec_Icon_pic' /></div></div>
                {/* <p className="card-text card-text-sess-deco"><span style={{color:"#ffb703"}}><AccessTimeIcon/></span>40 sessions</p> */}
                <h5 className="card-title">Type Rating - A320/B737/ATR/b-200/c-90</h5>
                <p className="card-text card-text-deco">Type Rating is a certification provided to ensure that the pilot is fit to fly certain types of aircrafts. Our Institute provides Type Rating courses for all major commercial airliners in India like the Airbus 320, Boeing 737 Max and ATR 72-600.</p>
                <button className='WhatWeOffer_btn' onClick={() => { handleForm() }}>Enroll Now <EastIcon style={{ fontSize: "small" }} /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
