import React from 'react';
import logo from '../Images/Avaition_Logo.png'
import './Header.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import  AlertDialogSlide   from './Dialog';
export default function Header({setOpenForm}) {
  const navigate = useNavigate();
   
  
  return (
   
    <div className='Nav_container'>
      <nav className="navbar navbar-expand-xxl bg-light">
        <div className="container-fluid">
           <img src={logo} alt=""className='Logo_img' /> 
          <div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"> </span>
          </button>
          
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                
                <Link className="nav-link active Nav_btn" to="/"> HOME </Link>
              </li>
              <li className="nav-item">
                {/* <a className="nav-link active Nav_btn" href="/aboutus">ABOUT US</a> */}
                <Link className="nav-link active Nav_btn" to="/aboutus"> ABOUT US </Link>
              </li>
              <li className="nav-item dropdown">
                {/* <a className="nav-link active Nav_btn" href="/contactus">PILOT MOCK TEST</a> */}
                <Link className="nav-link active Nav_btn" to="/contactus">PILOT MOCK TEST</Link>
              </li>
              <li className="nav-item">
                {/* <a className="nav-link active Nav_btn" href="/FAQ">FAQ</a> */}
                <Link className="nav-link active Nav_btn" to="/FAQ">FAQ</Link>
              </li>
              <li className="nav-item">
                {/* <a className="nav-link active Nav_btn" href="/contactus">GALLERY</a> */}
                <Link className="nav-link active Nav_btn" to="/contactus">GALLERY</Link>
              </li>
              <li className="nav-item">
                {/* <a className="nav-link active Nav_btn" href="/contactus">CONTACT US</a> */}
                <Link className="nav-link active Nav_btn" to="/contactus">CONTACT US</Link>
              </li>
            </ul>
            <button className='Join_Btn' onClick={()=>navigate('/contactus')}>JOIN NOW <div className='Header_arrowbtn'><ArrowForwardIcon  className='Arrow_sign'/></div></button>
            </div>
          </div>
        </div>
      </nav>
      
    </div>
     
  )
}
