import React ,{useEffect}from 'react'
import Header from '../Components/Header'
import PageHeadSec from '../Components/PageHeadSec'
import FAQList from '../Components/FAQList'
import Footer from "../Components/Footer"
import AlertDialogSlide from '../Components/Dialog';
import { useLocation } from 'react-router-dom';
export default function FAQpage({ openForm ,setOpenForm}) {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Header setOpenForm={setOpenForm}/>
      <PageHeadSec PageName={"FAQ"}/>
      <FAQList/>
      <Footer/>
      { <AlertDialogSlide  openForm={openForm} setOpenForm={setOpenForm} />}
    </div>
  )
}
