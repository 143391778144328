import React from 'react'
import './FAQList.css'
export default function FAQList() {
  let arr=[{heading:"What does Anurag Aviator Classes do?",content:"Anurag Aviator Classes is a leading aviation company primarily based in New Delhi with wide range of expertise on commercial pilots training."
  },

  {heading: "Why do I choose Anurag Aviator Classes?",content:"We understand the aviation industry well and ensure quality and excellence in commercial pilot training to our aspiring future pilots. Our result in both DGCA On Demand and Regular attempts is a testimony of that."},

  {heading:"What is the average duration of the Training?",content:"Average time to complete the ground school is six to eight months. After that flying training also takes around 8-10 months. A cadet can achieve the commercial pilot license within 2 years if he/she is consistent and disciplined."},

  {heading:"What is the training methodology?",content:"Anurag Aviator Classes offers zero hours to CPL under one roof. We have designed our notes in a manner that not only the theoretical knowledge, but also the latest questions can be practiced in the class. After, every chapter there are back questions given in the book, which are given as homework. On the next day, the doubts of previous session are discussed along with a small oral test to make sure, the entire batch has understood the concepts. If any one or two students face any problems, there doubts are dealt separately."},

  {heading:"What is the fees structure and what are benefits included?",content:"Anurag Aviator Classes provides quality training at a reasonable cost. For latest updates & discounts you can send us your queries by pressing link “Join Now” given above."},

  {heading:"What differentiates Anurag Aviator Classes from other aviation schools?",content:" Fundamentally, we keep a batch of around 15-20 students so that we can provide individual attention to each student. We make sure that students not only get in depth knowledge but also achieve great results as well. Our Question banks and Test Papers are extracted from latest DGCA papers which gives our students a cut above the rest."},

  {heading:"Does Anurag Aviator Classes offers a job after training with them?",content:"We provide job assistance to all our students. The preparation and important information about job is shared by us but it is up to the candidate’s confidence and skill to grab the job opportunity."},

  {heading:"What is the minimum age to start your pilot career?",content:"You can start your aviation career at the age of 16, but you should be minimum 18 years old by the time you get your Commercial Pilot License."},

  {heading:"What are the requirements to begin your pilot career?",content:"The key requirement to become a pilot is 10+2 with physics and maths and DGCA medicals. In case you do not have physics and maths in 10+2 you can still become a pilot by giving physics and maths exam from NIOS."},

  {heading:" Are DGCA medical exam very tough?",content:"The medical examination is a general body check-up comprising of blood tests, eye tests, ENT etc. There is a misconception that DGCA medicals are as strict as SSB and Air Force Medicals but that is not the case. You can check DGCA CAR Section 7 on their website for more details."},

  {heading:"Is graduation mandatory to become a pilot?",content:"There is no requirement laid down by airlines for pilots to be graduates, but it is highly beneficial in the long run. You can do graduation from open universities alongside your flying to save time. Our team of experts provide full assistance for graduations as well."},

  {heading:" Should you do your Flying first or DGCA Exams first?",content:"It is entirely upon the candidate as there is no hard and fast rule. But it is highly recommended to complete your DGCA Exams first as you will already have a basic knowledge of aircrafts before actually flying them. Your training will also be fast as exam cleared students are preferred in flying schools all over the world."},

  {heading:"You should do your flying from India or Abroad?",content:"It is completely your choice again. Both have their pros and cons. Flying duration will be almost same  but to convert your license you need additional conversion hours in India which takes additional time and cost. Our academy provides assistance for flying in both abroad and India."}
]
  return (
    <div className='Accordion_container'>
      <div className='Accordion_inner'>
      <div className="accordion" id="accordionExample">
      {arr.map((e,i)=>{
        return(
          <div className="accordion-item " key={i}>
        <h2 className="accordion-header" id={`heading${i+1}`} >
          <button
            className="accordion-button collapsed "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${i+1}`}
            aria-expanded="false"
            aria-controls={`collapse${i+1}`}
          >
            {e.heading}
          </button>
        </h2>
        <div
          id={`collapse${i+1}`}
          className="accordion-collapse collapse"
          aria-labelledby={`heading${i+1}`}
          data-bs-parent="#accordionExample"
          
        >
          <div className="accordion-body">
             {e.content}
          </div>
        </div>
        </div>
        )
      }) }
      </div>
    </div>
    </div>
  )
}
