import React from "react";
import "./Footer.css";
import logo from "../Images/Avaition_Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <div className="Footer_Container">
      <div className="Footer_address">
        <img className="Footer_logo" src={logo} alt="" />
        <p className="Footer_addressContent">
          Anurag Aviator Classes is a Ground school service which deals with
          flight crew to clear up the DGCA CPL/ATPL Examinations . Our
          management comprises of Pilots and Aviation professionals.
        </p>
      </div>
      <div className="Footer_Link">
        <h2 className="Footer_Link_heading">Quick Links</h2>
        <ul className="Footer_link_list">
          <li>
            <FontAwesomeIcon
              className="Footer_arrow"
              icon={faCaretRight}
              flip
            />
            <Link className="Footer_link_deco" to="/aboutus">
              About Us
            </Link>{" "}
          </li>
          <li>
            <FontAwesomeIcon
              className="Footer_arrow"
              icon={faCaretRight}
              flip
            />
            <Link className="Footer_link_deco" to="/contactus">
              Pilot Exam Prepration
            </Link>{" "}
          </li>
          <li>
            <FontAwesomeIcon
              className="Footer_arrow"
              icon={faCaretRight}
              flip
            />
            <Link className="Footer_link_deco" to="/FAQ">
              FAQ
            </Link>{" "}
          </li>
          <li>
            <FontAwesomeIcon
              className="Footer_arrow"
              icon={faCaretRight}
              flip
            />{" "}
            <Link className="Footer_link_deco" to="/contactus">
              Gallery
            </Link>{" "}
          </li>
          <li>
            <FontAwesomeIcon
              className="Footer_arrow"
              icon={faCaretRight}
              flip
            />
            <Link className="Footer_link_deco" to="/contactus">
              Contact Us
            </Link>{" "}
          </li>
        </ul>
      </div>
      <div className="Footer_ContactUs">
        <h2 className="Footer_ContactUs_heading">Contact Us</h2>
        <ul className="Footer_link_list">
          <li className="Footer_link_deco">
            <FontAwesomeIcon
              className="Footer_arrow"
              icon={faLocationDot}
              bounce
            />{" "}
            D-410,2nd Floor Near Burger Club,Ramphal Chowk, Dwarka Sec-7,New
            Delhi-110075
          </li>
          <li className="Footer_link_deco">
            <FontAwesomeIcon className="Footer_arrow" icon={faPhone} shake />{" "}
            +91 9711108932 ,+91 8800857231{" "}
          </li>
          <li className="Footer_link_deco">
            <FontAwesomeIcon className="Footer_arrow" icon={faEnvelope} beat />{" "}
            anuragaviatorcareer@gmail.com
          </li>
        </ul>
      </div>
    </div>
  );
}
