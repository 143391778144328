import React from 'react'
import './Testmonials.css'
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import EastIcon from '@mui/icons-material/East';
// import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
// import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faMessage,  } from '@fortawesome/free-solid-svg-icons';
import somedattaPic from "../Images/SomedattaPic.png";
import akash from "../Images/AkashKumar.png";
import atharv from "../Images/AtharvDaithankar.png";
import zoravar from "../Images/zoravarSingh.jpeg";
import devika from "../Images/Devika.jpeg"
import priyanka from "../Images/Priyanka.jpeg"


export default function Testmonials() {
  let data = [{ name: "Somedatta Maity", message: "Anurag sir is an inspiration to all the students.thank you sir for giving personal attention to all students and guiding us to clear the exam in first attempt.", Pic: somedattaPic },
  { name: "Akash Kumar", message: "Everything was somthing new, exciting and full of knowledge with Anurag sir. Thank you sir for helping me to pass the exam in first attempt", Pic: akash },
  { name: "Atharv Daithankar", message: "Anurag sir just makes everything so easy to understand and guides during whole journey . He is really helpful thats why i clear the exam in first attempt.Thankyou sir", Pic: atharv },
  { name: "Zorawar singh", message: "He makes every topic easy to understand and remember.He helped me allot!!!", Pic: zoravar },
  { name: "Devika", message: "Thanks you sir for making my dream come true and guiding me on every path that helped me to get selected in first attempt", Pic: devika },
  { name: "Priyanka", message: "Sir is an amazing mentor. Thank you sir for giving personal attention to all students and guiding us to clear the exam in first attempt.", Pic: priyanka },
  // { name: "Anurag", message: "Air navigation involves piloting an aircraft from place to place without breaking the laws applying to aircraft, or endangering the safety of flight.", Pic: somedattaPic },
  // { name: "Anurag", message: "Air navigation involves piloting an aircraft from place to place without breaking the laws applying to aircraft, or endangering the safety of flight.", Pic: somedattaPic },
  ]

  return (
    <div className='Testimonials'>
      <h1 className='Testimonial_heading'>Testmonials <FontAwesomeIcon icon={faFlag} bounce /></h1>
      <h1 style={{ fontSize: "6vh", fontWeight: "700", color: "#003566", marginBottom: "7vh" }}>Cadet Thoughts <span style={{ color: "#ffc300" }}> About us </span></h1>
      <div className='card_Container'>
        <div className="row   row-cols-md-4 row-cols-sm-2 row-cols-xs-1 g-4">

          {data.map((element, index) => {

            return (
              <div className="col" key={index}>
                <div className="card">
                  <div className="card-body">
                    <div className='TestimonialSec_Icon'><div className='Testimonial_IconPic_container'><img src={element.Pic} alt={element.name} className='Testimonial_IconPic' />
                    </div></div>
                    <p className="card-text testmonials_flag"><span style={{ color: "#ffb703", fontSize: "4vh" }}> <FontAwesomeIcon style={{ color: "#ffb703", fontSize: "7vh" }} icon={faMessage} beatFade /></span> </p>
                    <p className="card-text card-text-deco" style={{ marginTop: "15px" }}>{element.message}</p>
                    <h5 className="card-title" style={{ marginTop: "5vh" }}>{element.name} </h5>
                  </div>
                </div>
              </div>
            )
          })}




        </div>
      </div>
    </div>
  )
}
