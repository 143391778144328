import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import p6 from '../Images/P6.jpg'
import './WhyChooseUs.css'
export default function WhyChooseUs({setOpenForm}) {
  const handleForm=()=>{
    setOpenForm(true);
  }
  return (
    <div className='WhyChooseUs_Container'> 
      <div className='WhyChooseUs_left'>
       <img src={p6} alt="" className='whyChooseUs_pic'/>
      </div>
      <div className='WhyChooseUs_Right'>
        <div className='WhyChooseUs_Right_container'>
        <h3 className='heading_3'>WHY CHOOSE US</h3>
        <h1 className='heading_2'>We Build The <span style={{color:"#ffc300"}}>Dream With</span> Passion For You</h1>
        <p className='para_'>Setup in 2023, Anurag Aviator Classes is a ground school service which aims to clear the DGCA CPL and ATPL exam for Flight Crew and Trainee Pilots. Our motive is to facilitate Quality & Professional Pilot Training Programs.</p>
        <div className='ul_container' >
       <ul className='AboutusSec_list'>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Both Online & Offline Sessions</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Concept Based Training</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Experienced Faculty</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Reasonable Pricing</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Learn From Airline/Charter Pilots</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Free Study Materials</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Exam Specific Notes and Question Bank</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Full Assurance of all your DGCA Exams</span></li>
       </ul>
       </div>
       <button className='Join_Btn'onClick={()=>{handleForm()}}>JOIN NOW<div className='Header_arrowbtn'><ArrowForwardIcon  className='Arrow_sign'/></div></button>
       </div>
      </div>
    </div>
  )
}
