import React from 'react'
import './HomeAboutusSec.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AboutSecPic from '../Images/p3.jpg'
import DotPic from '../Images/Dot.png'
export default function HomeAboutusSec() {
  return (
    <div className='HomeAboutUsSec_Countainer'>
      <div className='HomeAboutUsSec_left'>
       <h4 className='AboutusSec_heading'>ABOUT US</h4>
       <h1 className='AboutusSec_heading_main'>TAKE-OFF YOUR <span style={{color:"#ffc300"}}>AVIATION CAREER</span> WITH US!!</h1>
       <div className='AboutusSec_para'>
       <p>We are a pilot training center operated by airline pilots with industry level experience, information & resources to facilitate leading professional pilot programs.</p>
       </div>
       <div className='ul_container' >
       <ul className='AboutusSec_list'>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Exam Specific Notes & Question Bank.</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Test Series from latest DGCA Questions.</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Regular Doubt Clearing Sessions.</span></li>
        <li className='AboutusSec_list_content'><div className='Done_Icon'><CheckCircleIcon/></div> <span> Complete assurance of your DGCA Exams.</span></li>
       </ul>
       </div>
       <button className='Join_Btn'>DISCOVER MORE <div className='Header_arrowbtn'><ArrowForwardIcon  className='Arrow_sign'/></div></button>
      </div>
      <div className='HomeAboutUsSec_right'>
       <div className='HomeAboutUsSec_right_frame'></div>
       <img src={DotPic} alt=""className='Dot_icon'/> 
       <img src={AboutSecPic} alt="" className='AboutUsSec_Pic'/>
      </div>
    </div>
  )
}
