import React from 'react'
import PilotPic from '../Images/P1.jpg';
import pilotPic1 from '../Images/p2.jpeg';
import pilotPic2 from '../Images/pilot-2514008_1280.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function CarouselSec({setOpenForm}) {
  const handleForm=()=>{
    setOpenForm(true);
  }
  return (
    <div>
      <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
  <div className="carousel-inner    ">
    <div className="carousel-item   ">
      <img src={pilotPic1} className="d-block  w-100" alt="..."/>
      <div className="carousel-caption">
        <h3 className='Carocel_content_H3'>BECOME A PILOT</h3>
        <h1 className='Carocel_content_Main'>TRUE TO LET</h1>
        <h1 className='Carocel_content_color'>YOUR DREAMS</h1>
        <h1 className='Carocel_content_Main'>TAKE FLIGHT!!!</h1>
        <p className='Carocel_content_para'>Anurag Aviator classes is a one stop solution for gearing up your carrier in Pilot Programs from scratch to Your Dreams by providing services of CPL ,ATPL , AIRLINE PREPRATION.</p>
        <button className='Carocel_btn_1'>ABOUT US<div className='Header_arrowbtn'><ArrowForwardIcon  className='Arrow_sign'/></div></button>
      </div>
    </div>
    <div className="carousel-item">
      <img src={pilotPic2} className="d-block w-100" alt="..."/>
      <div className="carousel-caption">
        <h3 className='Carocel_content_H3' style={{color:"#e0e1dd"}}>HURRYUP ENROLE NOW!!!!</h3>
        <h1 className='Carocel_content_Main'>CPL ATPL AIRLINE</h1>
        <h1 className='Carocel_content_color'>REGULATIONS EXAM</h1>
        <h1 className='Carocel_content_Main'style={{color:"#415a77"}}>PREPRATIONS</h1>
        <p className='Carocel_content_para' style={{color:"white"}}>This course series is designed to give you complete ground knoledge and concepts to clear your CPL ATPL exams.We specifically designed this course through custom made learning solutions developed using advance methodologies </p>
        <div className='Carosel_Btn_sec'>
        <button className='Carocel_btn_1 Carosel_Btn_Extra' onClick={()=>{handleForm()}}>REQUEST FOR DETAILS<div className='Header_arrowbtn'><ArrowForwardIcon  className='Arrow_sign'/></div></button>
        <button className='Carocel_btn_1'>ABOUT US<div className='Header_arrowbtn'><ArrowForwardIcon  className='Arrow_sign'/></div></button>
        </div>
      </div>
    </div>
    <div className="carousel-item active">
      <img src={PilotPic} className="d-block w-100" alt="..."/>
      <div className="carousel-caption">
        <h3 className='Carocel_content_H3' style={{color:"#e0e1dd"}}>ACCELERATE YOUR AVIATION CAREER!!!!</h3>
        <h1 className='Carocel_content_Main'>AND GIVE SHAPE TO YOUR FLYING DREAM</h1>
        <h1 className='Carocel_content_color'>WITH ANURAG AVIATOR CLASSES</h1>
        <h1 className='Carocel_content_Main'style={{color:"#001d3d"}}>WHERE DREAMS TAKE FLIGHT!</h1>
        <p className='Carocel_content_para' style={{color:"white"}}>We welcome you and offer the best aviation institution to study, your preferred aviation program is important if you want to thrive in your career.</p>
        <div className='Carosel_Btn_sec'>
        <button className='Carocel_btn_1 Carosel_Btn_Extra' onClick={()=>{handleForm()}}>REQUEST FOR DETAILS<div className='Header_arrowbtn'><ArrowForwardIcon  className='Arrow_sign'/></div></button>
        <button className='Carocel_btn_1'>ABOUT US<div className='Header_arrowbtn'><ArrowForwardIcon  className='Arrow_sign'/></div></button>
        </div>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}
