import React,{useEffect} from 'react'
import PageHeadSec from '../Components/PageHeadSec';
import Header from '../Components/Header';
import MidSec from "../Components/HomeAboutusSec"
import Testmonials from '../Components/Testmonials'
import Footer from "../Components/Footer"
 
import SucessStories2 from "../Images/akashSucess.png";
import SucessStories3 from "../Images/atharvSucess.png";
import SucessStories4 from "../Images/somedataSucess.png";
import AlertDialogSlide from '../Components/Dialog';
import "./Style.css";
import {   useLocation } from 'react-router-dom';
export default function AboutUsPage({ openForm ,setOpenForm}) {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the route changes
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Header setOpenForm={setOpenForm}/>
      <PageHeadSec PageName={"About Us"} />
      <MidSec />
      <div className='SucessStories_Container'>
        <div className='SucessStories_inner'> <h1 className='SucessStories_heading'>OUR SUCCESS <span style={{ color: "#ffd60a" }}>STORIES</span></h1>
          <div className='SucessStories_Img_Container'>
            {/* <div className='SucessStories_Img_Frame'><img className='SucessStories_Img' src={SucessStories1} alt="SucessStories" /></div> */}
            <div className='SucessStories_Img_Frame'><img className='SucessStories_Img' src={SucessStories2} alt="SucessStories" /></div>
            <div className='SucessStories_Img_Frame'><img className='SucessStories_Img' src={SucessStories3} alt="SucessStories" /></div>
            <div className='SucessStories_Img_Frame'><img className='SucessStories_Img' src={SucessStories4} alt="SucessStories" /></div></div>
        </div>
      </div>
      <Testmonials />
      <Footer />
      { <AlertDialogSlide  openForm={openForm} setOpenForm={setOpenForm} />}
    </div>
  )
} 
