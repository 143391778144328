import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TextareaAutosize } from '@mui/base';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
// import axios from 'axios';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ openForm, setOpenForm }) {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phoneNum: "",
    message: ""
  });
  const handleClose = () => {
    setDetails({
      name: "",
      email: "",
      phoneNum: "",
      message: ""
    });
    setOpenForm(false);
  };

  const handleSubmit = async () => {
    let data = { name: details.name, email: details.email, phone: details.phoneNum, message: details.message };
    console.log(data);
    // let respose = await fetch('https://script.google.com/macros/s/AKfycbxT2UPJeEA3UfOkJzEAiFpMGKVwSb4FNA_97VETf4GYWIknadMq0UYJPAG2PCF4RUH4CQ/exec', { method: "POST", body: data })
    // let res = await respose.text();
    // console.log(res);
    setDetails({
      name: "",
      email: "",
      phoneNum: "",
      message: ""
    });
    setOpenForm(false);
  }

   
  return (
    <div>
      <Dialog
        open={openForm}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description" style={{ width: "100%" }}
      >
        <DialogTitle style={{ marginTop: "2vh", marginBottom: "2vh", fontSize: "2em" }}>Please share the details to contact</DialogTitle>
        <DialogContent style={{ paddingTop: "2vh" }}>

          <DialogContentText id="alert-dialog-slide-description">
            <Stack spacing={3}>
          <TextField value={details.name} id="outlined-basic" label="Your Name" variant="outlined" onChange={(e)=>{setDetails({...details,name:e.target.value})}} />
          <TextField value={details.email} id="outlined-basic" label="Email" variant="outlined" onChange={(e)=>{setDetails({...details,email:e.target.value})}}/>
          <TextField value={details.phoneNum} id="outlined-basic" label="Phone Number" variant="outlined" onChange={(e)=>{setDetails({...details,phoneNum:e.target.value})}}/>
          <TextareaAutosize aria-label="minimum height"
          value={details.message}
            minRows={3}
             placeholder="Your Message" onChange={(e)=>{setDetails({...details,message:e.target.value})}}/>
             </Stack>
             
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => { handleSubmit() }} style={{ fontSize: "1.1rem" }}>submit</Button>
          <Button onClick={() => { handleClose() }} style={{ color: "red", fontSize: "1.1rem" }}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}